import React from 'react';
import {t} from 'i18next';
import {useWindowWidth} from 'hooks/useWindowWidth';
import TechCarousel from './TechCarousel/TechCarousel';
import TechLogos from './TechLogos/TechLogos';
import './TechWeUse.scss';

const TechWeUse: React.FC = (): any => {
  const {width} = useWindowWidth();

  const mobileWidthBreakpoint = 640;

  return (
    <div className="tools-and-frameworks">
      <h2
        className='section-heading tools-and-frameworks__header'
      >
        {t('Tools and frameworks')}
      </h2>

      {width > mobileWidthBreakpoint ? <TechLogos /> : <TechCarousel />}
    </div>
  );
};

export default TechWeUse;
