import IconButton from 'components/Buttons/IconButton/IconButton';
import React from 'react';
import {t} from 'i18next';
import './MessageUs.scss';

interface IModalProp {
  onClick: any;
}

const MessageUs: React.FC<IModalProp> = ({onClick}): any => {
  return (
    <div className='message-us'>
      <h2 className='section-heading message-us__heading'>{t('From message')}</h2>
      <p>{t('Contact us')}</p>
      <div className="message-us__btn-container">
        <IconButton title={t('Contact Us')} onClick={onClick} />
      </div>
    </div>
  );
};

export default MessageUs;
