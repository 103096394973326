import React, {useEffect} from 'react';
import {t} from 'i18next';
import {PageView} from 'google-analytics/PageView';
import {useScrollToTop} from 'hooks/useScrollToTop';
import {BiMouse} from 'react-icons/bi';
import './Presentation.scss';

const Presentation: React.FC = (): any => {
  useScrollToTop();

  useEffect(() => {
    PageView();
  }, []);

  return (
    <div className='presentation-page'>

      <div className="presentation-page__body">
        <div className="presentation-page__content-wrapper content-wrapper">
          <div className="presentation-page__content-container">
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FGJPBmZNfctduCnajzMEORj%2FPREZENTACIJA%3Fpage-id%3D0%253A1%26node-id%3D0%253A3%26viewport%3D622%252C427%252C0.06%26scaling%3Dcontain" allowFullScreen />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
