/* eslint-disable max-len */
import React, {useRef} from 'react';
import {t} from 'i18next';
import {IoClose} from 'react-icons/io5';
import {Link, useLocation} from 'react-router-dom';
import LanguageSelector from 'components/LanguageSelect/LanguageSelect';
import {useWindowWidth} from 'hooks/useWindowWidth';
import {EventTracker} from 'google-analytics/EventTracker';
import {CSSTransition} from 'react-transition-group';
import {
  HOME_ABOUT, HOME_SERVICES, HOME_CLIENTS, HOME_CONTACTS, OPEN_POSITIONS
} from 'constants/routes';
import './SideMenu.scss';

interface propsInterface {
  sideMenuOpen: boolean;
  currentLanguage: string | null;
  setSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string | null>>;
}

const SideMenu: React.FC<propsInterface> = ({
  sideMenuOpen, setSideMenuState, currentLanguage, setCurrentLanguage
}): any => {
  const location = useLocation();

  const closeSideMenu = (): void => {
    if (sideMenuOpen) {
      setSideMenuState(false);
    }
  };

  const nodeRef = useRef(null);

  const {width} = useWindowWidth();

  const mobileBreakPoint = 640;

  return (
    (width < mobileBreakPoint && (
      <CSSTransition
        in={sideMenuOpen}
        appear
        timeout={{
          appear: 0,
          enter: 0,
          exit: 500
        }}
        mountOnEnter
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(state) => (
          <div ref={nodeRef}>

            <div className="side-menu__overlay" onClick={closeSideMenu} />

            <div className={`side-menu side-menu--${state}`}>
              <IoClose className='side-menu__close-btn' onClick={closeSideMenu} />

              <div className="side-menu__navigation-wrapper">
                <nav className='side-menu__navigation-container'>
                  <a
                    className={`side-menu__nav-link ${location.pathname + location.hash === HOME_ABOUT ?
                      'active' : ''}`}
                    href={HOME_ABOUT}
                    onClick={() => EventTracker('Link', 'click to navigate', HOME_ABOUT)}
                  >
                    {t('About Us')}
                  </a>

                  <a
                    className={`side-menu__nav-link ${location.pathname + location.hash === HOME_SERVICES ?
                      'active' : ''}`}
                    href={HOME_SERVICES}
                    onClick={() => EventTracker('Link', 'click to navigate', HOME_SERVICES)}
                  >
                    {t('Services')}
                  </a>

                  <a
                    className={`side-menu__nav-link ${location.pathname + location.hash === HOME_CLIENTS ?
                      'active' : ''}`}
                    href={HOME_CLIENTS}
                    onClick={() => EventTracker('Link', 'click to navigate', HOME_CLIENTS)}
                  >
                    {t('Clients')}
                  </a>

                  <Link
                    className={`side-menu__nav-link ${location.pathname + location.hash === OPEN_POSITIONS ?
                      'active' : ''}`}
                    to={OPEN_POSITIONS}
                    onClick={() => EventTracker('Link', 'click to navigate', OPEN_POSITIONS)}
                  >
                    {t('Career')}
                  </Link>

                  <a
                    className={`side-menu__nav-link ${location.pathname + location.hash === HOME_CONTACTS ?
                      'active' : ''}`}
                    href={HOME_CONTACTS}
                    onClick={() => EventTracker('Link', 'click to navigate', HOME_CONTACTS)}
                  >
                    {t('Contacts')}
                  </a>
                </nav>

                <LanguageSelector
                  currentLanguage={currentLanguage}
                  setCurrentLanguage={setCurrentLanguage}
                  isInSideMenu
                />
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    ))
  );
};

export default SideMenu;
