/* eslint-disable react/button-has-type */
import React from 'react';
import {GrSend} from 'react-icons/gr';
import {EventTracker} from 'google-analytics/EventTracker';

interface IButtonProps {
  title: string,
  onClick?: any,
  type?: 'submit' | 'reset' | 'button',
  iconHidden?: boolean
}

const IconButton: React.FC<IButtonProps> = ({
  title, onClick, type, iconHidden
}): any => {
  const onClickActions = (): any => {
    if (onClick) {
      onClick();
    }
    EventTracker('Button', 'click', `path: "${window.location.pathname}" title: ${title}`);
  };

  return (
    <button className='button-with-icon' type={type} onClick={onClickActions}>
      {!iconHidden &&
      (
        <span className='button-with-icon__icon-container'>
          <GrSend className='button-with-icon__icon-container__icon' />
        </span>
      )}
      <span className='button-with-icon__title'>{title}</span>
    </button>
  );
};

IconButton.defaultProps = {onClick: null, type: 'button', iconHidden: false};

export default IconButton;
