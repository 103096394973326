import React, {useEffect} from 'react';
import {useScrollToTop} from 'hooks/useScrollToTop';
import {t} from 'i18next';
import {BiMouse} from 'react-icons/bi';
import {PageView} from 'google-analytics/PageView';
import './Privacy.scss';

const Privacy: React.FC = (): any => {
  useScrollToTop();

  useEffect(() => {
    PageView();
  }, []);

  return (
    <div className='privacy-page'>
      <div className="hero-block">
        <h1 className='hero-block__header'>{t('Your privacy')}</h1>

        <div className='hero-block__scroll-icon-box'>
          <BiMouse />
        </div>
      </div>

      <div className="privacy-page__body">
        <div className="privacy-page__content-wrapper content-wrapper">
          <div className="privacy-page__content-container">
            <h2 className='section-heading'>{t('Your privacy is important')}</h2>
            <p>{t('Privacy paragraph')}</p>
          </div>

          <div className="privacy-page__content-container">
            <h2 className='privacy-page--heading section-heading'>{t('Log data')}</h2>
            <p>{t('Log data paragraph')}</p>
          </div>

          <div className="privacy-page__content-container">
            <h2 className='privacy-page--heading section-heading'>
              {t('Collection and Use of Information')}
            </h2>
            <p>
              {t('Collection paragraph')}
            </p>
            <br />
            <p>
              {t('Collection paragraph second')}
            </p>
          </div>

          <div className="privacy-page__content-container">
            <h2 className='privacy-page--heading section-heading'>
              {t('How Long We Keep Your Personal Information')}
            </h2>
            <p>
              {t('Personal Information paragraph')}
            </p>
            <br />
            <p>
              {t('Questions or concerns')}
              <a className='privacy-page__content-container__link' href='mailto:info@mipsas.com'>
                info@mipsas.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
