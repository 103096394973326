/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import React, {useEffect} from 'react';
import {t} from 'i18next';
import {BiMouse} from 'react-icons/bi';
import {PageView} from 'google-analytics/PageView';
import DocumentSvg from 'assets/images/icons/document_1.svg';
import HrImg from 'assets/images/people/hr.png';
import BubbleBox from 'components/BubbleBox/BubbleBox';
import WeOfferSection from 'components/WeOfferSection/WeOfferSection';
import JobLink from 'components/JobLink/JobLink';
import PersonDetailsBox from 'components/PersonDetailsBox/PersonDetailsBox';
import JobsDataEN from 'data/JobsDataEN.json';
import JobsDataLT from 'data/JobsDataLT.json';
import {useScrollToTop} from 'hooks/useScrollToTop';
import InfoBox from 'components/InfoBox/InfoBox';
import {HiLocationMarker, HiMail} from 'react-icons/hi';
import './OpenPositions.scss';

interface ILanguageProps {
  currentLanguage: string | null,
}

const emailBoxTitle = 'info@mipsas.com';

const addressBoxTitle = 'Pasimatymo aklg. 5-9, Kaunas';

const OpenPositions: React.FC<ILanguageProps> = ({currentLanguage}): any => {
  useScrollToTop();

  useEffect(() => {
    PageView();
  }, []);

  const renderJobLinks = () => {
    if (currentLanguage === 'lt') {
      return JobsDataLT.map((job) => {
        return (
          <JobLink
            key={job.title}
            title={job.title}
            location={job.location}
            url={job.url}
          />
        );
      });
    }

    return JobsDataEN.map((job) => {
      return (
        <JobLink
          key={job.title}
          title={job.title}
          location={job.location}
          url={job.url}
        />
      );
    });
  };

  return (
    <div className='open-positions'>
      <div className='hero-block hero-block--with-background'>

        <h1 className='hero-block__header'>
          {t('We are looking for')}
        </h1>

        <span
          className='hero-block__info-box'
        >
          {t('New team members')}
        </span>

        <p className='hero-block__sm-text'>{t('We seek')}</p>

        <div className='hero-block__scroll-icon-box'>
          <BiMouse />
        </div>
      </div>

      <section className='open-positions__all-positions'>
        <div className="content-wrapper">
          <div className="open-positions__all-positions__content-container">
            <BubbleBox
              mode="icon"
              containerClassName="open-positions__all-positions__bubble-box"
              imgClassName="open-positions__all-positions__document-icon"
              imgSource={DocumentSvg}
            />

            <h2 className='open-positions__all-positions__heading section-heading'>
              {t('All open positions')}
            </h2>

            <div className="open-positions__all-positions__jobs-container">
              {renderJobLinks()}
            </div>
          </div>
        </div>
      </section>

      <WeOfferSection />

      <section className="open-positions__contact-us">
        <div className="content-wrapper open-positions__contact-us__wrapper">
          <h2 className='section-heading open-positions__contact-us__header'>
            {t('Cant find positions')}
          </h2>

          <div className="open-positions__contact-us__content-container">
            {/* <div className="open-positions__contact-us__content-container__person-details">
              <PersonDetailsBox
                imgSource={HrImg}
                name="Agnė Kalvaitė"
                position="Human Resources"
                number="+3706 9444939"
                email="agne.k@mipsas.com"
              />
            </div> */}

            <div className='open-positions__contact-us__info-box-container'>
              <InfoBox className='open-positions__contact-us__info-box-container__info-box' title={emailBoxTitle}>
                <HiMail className='info-box__icon' />
              </InfoBox>

              <InfoBox className='open-positions__contact-us__info-box-container__info-box' title={addressBoxTitle}>
                <HiLocationMarker className='info-box__icon' />
              </InfoBox>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OpenPositions;
