import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import LTFlag from 'assets/images/flags/LT_Flag.png';
import ENFlag from 'assets/images/flags/EN_Flag.png';
import ChevronDown from 'assets/images/shapes/chevron-down.svg';
import './LanguageSelect.scss';

const sideMenuDefaultProps = {
  isInSideMenu: false
};

interface languageInterface {
  currentLanguage: string | null;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string | null>>;
  isInSideMenu?: boolean;
}

const LanguageSelector: React.FC<languageInterface> = ({
  currentLanguage, setCurrentLanguage, isInSideMenu
}) => {
  const [dropdownOpen, setDropdownState] = useState<boolean>(false);

  const {i18n} = useTranslation();

  const changeLanguage = (lng: string): void => {
    setCurrentLanguage(lng);
    setDropdownState(false);
    i18n.changeLanguage(lng);
  };

  const toggleDropdown = () => {
    if (dropdownOpen) {
      setDropdownState(false);
    } else {
      setDropdownState(true);
    }
  };

  return (
    <div className='language-select__wrapper'>
      {!isInSideMenu ? (
        <>
          <div className="language-select language-select--desktop">
            <div className="language-select__container">
              <div
                className={`language-select__selector 
                ${currentLanguage === 'en' ? 'selector--active' : ''}`}
                onClick={() => changeLanguage('en')}
              >
                <img src={ENFlag} alt="English language selector flag" />
              </div>

              <div
                className={`language-select__selector 
                ${currentLanguage === 'lt' ? 'selector--active' : ''}`}
                onClick={() => changeLanguage('lt')}
              >
                <img src={LTFlag} alt="Lithunian language selector flag" />
              </div>
            </div>
          </div>

          <div className="language-select language-select--tablet">
            <div className="language-select__container selector--tablet">
              <div
                className="language-select__selector selector--active"
                onClick={toggleDropdown}
              >
                <img
                  src={currentLanguage === 'en' ? ENFlag : LTFlag}
                  alt="English language selector flag"
                />
              </div>

              <img
                className={`language-select__chevron 
              ${dropdownOpen ? 'chevron--not-visible' : 'chevron--visible'}`}
                src={ChevronDown}
                alt=""
              />
            </div>

            <div className={`language-select__dropdown 
              ${dropdownOpen ? 'dropdown-open' : 'dropdown-closed'}`}
            >
              <div
                className="language-select__selector"
                onClick={() => changeLanguage('en')}
              >
                <img src={ENFlag} alt="English language selector flag" />
              </div>

              <div
                className="language-select__selector "
                onClick={() => changeLanguage('lt')}
              >
                <img src={LTFlag} alt="Lithunian language selector flag" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="language-select">
          <div className="language-select__container">
            <div
              className={`language-select__selector 
            ${currentLanguage === 'en' ? 'selector--active' : ''}`}
              onClick={() => changeLanguage('en')}
            >
              <img src={ENFlag} alt="English language selector flag" />
            </div>

            <div
              className={`language-select__selector 
            ${currentLanguage === 'lt' ? 'selector--active' : ''}`}
              onClick={() => changeLanguage('lt')}
            >
              <img src={LTFlag} alt="Lithunian language selector flag" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LanguageSelector.defaultProps = sideMenuDefaultProps;

export default LanguageSelector;
