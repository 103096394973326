import React from 'react';
import {t} from 'i18next';
import Illiustration from 'assets/images/illiustrations/person-illiustration.svg';
import './AboutUs.scss';

const AboutUs: React.FC = () => {
  return (
    <div id="about" className='about-us'>
      <div className="content-wrapper about-us__content-wrapper">
        <img src={Illiustration} alt="Illiustration of a person with a laptop" />
        <div className="about-us__intro-container">
          <h2 className='section-heading about-us__section-heading'>{t('Iterations')}</h2>
          <p>{t('We are')}</p>
          <p>{t('We keep')}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
