/* eslint-disable max-len */
import React from 'react';
import {t} from 'i18next';
import {HiMail, HiLocationMarker} from 'react-icons/hi';
import {FaFacebook, FaLinkedinIn} from 'react-icons/fa';
import {EventTracker} from 'google-analytics/EventTracker';
import IconButton from 'components/Buttons/IconButton/IconButton';
import InfoBox from 'components/InfoBox/InfoBox';
import './CompanyInfo.scss';

interface ICompanyInfoProps {
  onClick?: any,
}

const emailBoxTitle = 'info@mipsas.com';

const addressBoxTitle = 'Pasimatymo aklg. 5-9, Kaunas';

const CompanyInfo: React.FC<ICompanyInfoProps> = ({onClick}): any => {
  return (
    <div className="company-info">
      <div className="company-info__info-box-container">
        <IconButton title={t('Contact Us')} onClick={onClick} />

        <InfoBox className='company-info__info-box-container__info-box' title={emailBoxTitle}>
          <HiMail className='info-box__icon' />
        </InfoBox>

        <InfoBox isLink={false} className='company-info__info-box-container__info-box' title={addressBoxTitle}>
          <HiLocationMarker className='info-box__icon' />
        </InfoBox>
      </div>

      <div className="company-info__social-media-container">

        <a
          href='https://www.facebook.com/MIPSASMB'
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook page of Mipsas company"
          className='company-info__social-media-box'
          onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: Facebook`)}
        >
          <FaFacebook className='company-info__social-media-box__social-media-icon' />
        </a>

        <a
          href='https://www.linkedin.com/company/mipsas-mb'
          target="_blank"
          rel="noreferrer"
          aria-label="Linkedin page of Mipsas company"
          className='company-info__social-media-box'
          onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: Linkedin`)}
        >
          <FaLinkedinIn className='company-info__social-media-box__social-media-icon' />
        </a>
      </div>
    </div>
  );
};

CompanyInfo.defaultProps = {onClick: null};

export default CompanyInfo;
