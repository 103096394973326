export const HOME = '/';
export const HOME_SERVICES = '/#services';
export const HOME_ABOUT = '/#about';
export const HOME_CLIENTS = '/#clients';
export const HOME_CAREER = '/#career';
export const HOME_CONTACTS = '/#contact-us';
export const ES_PROJECTS = '/#eu-projects';
export const OPEN_POSITIONS = '/open-positions';
export const PRIVACY = '/privacy';
export const COOKIES = '/cookies';
export const PRESENTATION = '/presentation';
export const ERROR_PAGE = '*';
