import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from 'constants/en_translations.json';
import ltTranslation from 'constants/lt_translations.json';

const resources = {
  en: {
    translation: enTranslation
  },
  lt: {
    translation: ltTranslation
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
  });

export default i18n;
