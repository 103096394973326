import React, {useEffect, useState} from 'react';
import {PageView} from 'google-analytics/PageView';
import EstimateProjectModal from 'components/Modals/EstimateProject/EstimateProjectModal';
import HeroBlock from './HeroBlock/HeroBlock';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import MessageUs from './MessageUs/MessageUs';
import TechWeUse from './TechWeUse/TechWeUse';
import Clients from './Clients/Clients';
import WeAreHiringBlock from './WeAreHiring/WeAreHiring';
import ContactUs from './ContactUs/ContactUs';
import EuProjects from './EuProjects/EuProjects';

interface ILanguageProps {
  currentLanguage: string | null,
}

const Home: React.FC<ILanguageProps> = ({currentLanguage}): any => {
  const [isEstimateModalOpen, setIsEstimateModalOpen] = useState<boolean>(false);

  const changeEstimateModalState = () => {
    return isEstimateModalOpen ? setIsEstimateModalOpen(false) : setIsEstimateModalOpen(true);
  };

  useEffect(() => {
    PageView();
  }, []);

  return (
    <div className='home'>
      <EstimateProjectModal
        isEstimateModalOpen={isEstimateModalOpen}
        showEstimateModal={changeEstimateModalState}
      />
      <HeroBlock />
      <AboutUs />
      <Services />
      <MessageUs onClick={changeEstimateModalState} />
      <TechWeUse />
      <Clients />
      {/* <WeAreHiringBlock currentLanguage={currentLanguage} /> */}
      <EuProjects currentLanguage={currentLanguage} />
      <ContactUs changeEstimateModalState={changeEstimateModalState} />
    </div>
  );
};

export default Home;
