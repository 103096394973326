/* eslint-disable max-len */
import React, {useRef, useState} from 'react';
import {t} from 'i18next';
import {IoClose} from 'react-icons/io5';
import {emailRegex, noNumbersRegex} from 'constants/utilities';
import {CSSTransition} from 'react-transition-group';
import emailjs from '@emailjs/browser';
import Input from 'components/Input/Input';
import Select from 'components/CustomSelect/CustomSelect';
import ServicesData from 'data/ServicesData.json';
import TextArea from 'components/TextArea/TextArea';
import IconButton from 'components/Buttons/IconButton/IconButton';
import FormLoader from 'components/FormLoader/FormLoader';
import MessageSentModal from '../MessageSent/MessageSentModal';
import './EstimateProject.scss';

interface ISelectOption {
  value: string,
  label: string
}

interface IModalOpenProps {
  isEstimateModalOpen: boolean
  showEstimateModal: any,
}

interface IDefaultformValues {
  name: string,
  email: string,
  service: ISelectOption | any,
  message: string
}

const defaultformValues: IDefaultformValues = {
  name: '',
  email: '',
  service: null,
  message: ''
};

const defaultErrors = {
  name: '',
  email: '',
  service: '',
  message: '',
  submitError: ''
};

const EstimateProjectModal: React.FC<IModalOpenProps> = ({
  isEstimateModalOpen,
  showEstimateModal,
}): any => {
  const [formValues, setFormValues] = useState(defaultformValues);

  const [formErrors, setFormErrors] = useState(defaultErrors);

  const [formSending, setFormSending] = useState<boolean>(false);

  const [isMessageSentOpen, setIsMessageSentOpen] = useState<boolean>(false);

  const nodeRef = useRef(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormValues({...formValues, [name]: value});
  };

  const handleSelectChange = (e: any) => {
    setFormValues({...formValues, service: e});
  };

  const formInputValidation = (values: any) => {
    const errors: any = {};
    let isValid = true;

    if (!values.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    } else if (!noNumbersRegex.test(formValues.name)) {
      errors.name = 'No numbers allowed';
      isValid = false;
    } else {
      errors.name = '';
    }

    if (!values.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!emailRegex.test(formValues.email)) {
      errors.email = 'Enter a valid email address';
      isValid = false;
    } else {
      errors.email = '';
    }

    if (!values.service?.value) {
      errors.service = 'Please select a service';
      isValid = false;
    } else {
      errors.service = '';
    }

    if (!values.message.trim()) {
      errors.message = 'Please enter a message';
      isValid = false;
    } else {
      errors.message = '';
    }

    setFormErrors(errors);
    return isValid;
  };

  const resetForm = () => {
    setFormValues(defaultformValues);
    setFormErrors(defaultErrors);
  };

  const send = () => {
    const formData = {
      name: formValues.name,
      email: formValues.email,
      service: formValues.service.value,
      message: formValues.message
    };

    if (!formErrors.submitError) {
      setFormSending(true);
    }

    emailjs.send(
      process.env.REACT_APP_SERVICE_ID as string,
      process.env.REACT_APP_ESTIMATE_TEMPLATE_ID as string,
      {...formData},
      process.env.REACT_APP_USER_ID as string
    ).then(() => {
      setFormSending(false);
      showEstimateModal(false);
      setIsMessageSentOpen(true);
      resetForm();
    }).catch(() => {
      setFormSending(false);
      setFormErrors((prevState) => ({
        ...prevState, submitError: 'Something went wrong'
      }));
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (formInputValidation(formValues)) {
      send();
    }
  };

  const changeMessageSentState = () => {
    return isMessageSentOpen ? setIsMessageSentOpen(false) : setIsMessageSentOpen(true);
  };

  return (
    <>
      <CSSTransition
        in={isEstimateModalOpen}
        appear
        timeout={{
          appear: 0,
          enter: 0,
          exit: 500
        }}
        mountOnEnter
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(state) => (
          <div ref={nodeRef}>
            <div
              className={
                `estimate-project-modal-wrapper wrapper-${state}`
              }
              onClick={showEstimateModal}
            />
            <div className={`estimate-project-modal modal-${state}`}>

              {formSending ? <FormLoader /> : ''}

              <h2 className='section-heading estimate-project-modal__header'>
                {t('Estimate your project')}
              </h2>
              <IoClose className='estimate-project-modal__close-btn' onClick={showEstimateModal} />
              <form className='estimate-project-modal__form' onSubmit={handleSubmit}>
                <div className="estimate-project-modal__form__top-row">
                  <Input
                    label={t('Name and surname')}
                    placeholder={t('Name and surname placeholder')}
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={formErrors.name}
                  />

                  <Input
                    label={t('Email address')}
                    placeholder={t('Email placeholder')}
                    type="text"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    error={formErrors.email}
                  />
                </div>
                <div className="estimate-project-modal__form__service-container">
                  <Select
                    label={t('Service')}
                    placeholder={t('Choose Service')}
                    name='services'
                    options={ServicesData}
                    onChange={handleSelectChange}
                    value={formValues.service}
                    error={formErrors.service}
                  />
                </div>

                <div className="estimate-project-modal__form__message-container">
                  <TextArea
                    label={t('Tell us about your needs')}
                    placeholder={t('Message placeholder')}
                    name="message"
                    value={formValues.message}
                    onChange={handleInputChange}
                    error={formErrors.message}
                  />
                </div>

                <p className='form-error-message submit-error estimate-project-modal__form__error-message'>
                  {t(`${formErrors.submitError || ''}`)}
                </p>

                <div className="estimate-project-modal__form__btn-container">
                  <IconButton title={t('Complete and send')} type="submit" />
                </div>
              </form>
            </div>
          </div>
        )}
      </CSSTransition>
      <MessageSentModal
        messageSentOpen={isMessageSentOpen}
        closeMessageSent={changeMessageSentState}
      >
        <h2 className='message-sent-modal__header header--top'>
          {t('Thank you for your message')}
        </h2>
        <h4 className='message-sent-modal__header header--mid'>
          {t('We will write')}
        </h4>
        <h5 className='message-sent-modal__header header--bottom'>
          {t('Thank you for choosing mipsas')}
        </h5>
      </MessageSentModal>
    </>
  );
};

export default EstimateProjectModal;
