import React from 'react';
import {t} from 'i18next';
import {CircleLoader} from 'react-spinners';
import './FormLoader.scss';

const FormLoader: React.FC = (): any => {
  return (
    <div className="form-loader__overlay">
      <CircleLoader size={100} color='#368CE7' />
      <span className='form-loader__overlay__text'>{t('Sending')}</span>
    </div>
  );
};

export default FormLoader;
