/* eslint-disable no-magic-numbers */
import React, {useEffect, useState} from 'react';
import {t} from 'i18next';
import {Link} from 'react-router-dom';
import {COOKIES} from 'constants/routes';
import IconButton from 'components/Buttons/IconButton/IconButton';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import './CookiesBanner.scss';

const CookiesBanner: React.FC = (): any => {
  const [isCookiesAgreed, setIsCookiesAgreed] = useState<boolean>(false);

  const cookies = new Cookies();

  const cookieAgree = () => {
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    cookies.set('agree', true, {path: '/', expires: aYearFromNow});
    ReactGA.pageview(window.location.pathname + window.location.search);
    setIsCookiesAgreed(true);
  };

  useEffect(() => {
    if (cookies.get('agree')) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      setIsCookiesAgreed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isCookiesAgreed && (
      <div className='cookies-banner'>
        <div className="content-wrapper cookies-banner__content-wrapper">
          <p className='cookies-banner__info-block'>{t('Our website')}
            <Link className='cookies-banner__info-block__link' to={COOKIES}>
              {t('Learn more')}
            </Link>
          </p>
          <IconButton iconHidden title={t('I agree')} onClick={cookieAgree} />
        </div>
      </div>
    )
  );
};

export default CookiesBanner;
