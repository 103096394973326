import React from 'react';
import HtmlLogo from 'assets/images/tech-logos/html.svg';
import CssLogo from 'assets/images/tech-logos/css.svg';
import JsLogo from 'assets/images/tech-logos/javascript.svg';
import JavaLogo from 'assets/images/tech-logos/java.svg';
import ReactNativeLogo from 'assets/images/tech-logos/react-native.svg';
import FigmaLogo from 'assets/images/tech-logos/figma.svg';
import PSLogo from 'assets/images/tech-logos/ps.svg';
import AILogo from 'assets/images/tech-logos/ai.svg';
import ElasticLogo from 'assets/images/tech-logos/elastic.svg';
import DotNetCoreLogo from 'assets/images/tech-logos/dot-net-core.svg';
import PostgresLogo from 'assets/images/tech-logos/postgresql.svg';
import RedisLogo from 'assets/images/tech-logos/redis.svg';
import MySqlLogo from 'assets/images/tech-logos/mysql.svg';
import {Carousel} from 'react-responsive-carousel';
import './TechCarousel.scss';

const TechCarousel: React.FC = (): any => {
  return (
    <div className="custom-carousel">
      <Carousel
        centerSlidePercentage={100}
        centerMode
        showThumbs={false}
        showStatus={false}
        showArrows={false}
      >
        <div className="carousel__item-container">
          <img src={HtmlLogo} alt="Html 5 logo" />
          <img src={CssLogo} alt="Css 3 logo" />
          <img src={JsLogo} alt="Javascript logo" />
        </div>

        <div className="carousel__item-container">
          <img src={JavaLogo} alt="Java logo" />
          <img src={ReactNativeLogo} alt="Figma logo" />
          <img src={FigmaLogo} alt="Figma logo" />
        </div>

        <div className="carousel__item-container">
          <img src={PSLogo} alt="Adobe Photoshop logo" />
          <img src={AILogo} alt="Adobe illiustrator logo" />
          <img src={ElasticLogo} alt="Elasticsearch logo" />
        </div>

        <div className="carousel__item-container two-items">
          <img src={DotNetCoreLogo} alt="Dont net core logo" />
          <img src={PostgresLogo} alt="PostgresSQL logo" />
        </div>

        <div className="carousel__item-container two-items">
          <img src={RedisLogo} alt="Redis logo" />
          <img src={MySqlLogo} alt="MySQL logo" />
        </div>
      </Carousel>
    </div>
  );
};

export default TechCarousel;
