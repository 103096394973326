/* eslint-disable max-len */
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';
import {EventTracker} from 'google-analytics/EventTracker';
import './JobLink.scss';

interface IJobProps {
  title: string,
  location?: string | null,
  url: string,
}

const locationDefault = {location: null};

const JobLink: React.FC<IJobProps> = (props): any => {
  return (
    <Link
      className='job-position-link'
      to={`/open-positions/${props.url}`}
      onClick={() => EventTracker('Job Link', 'click to job details', `${`path: "${window.location.pathname}" link: "${props.url}"`}`)}
    >
      <span className='job-position-link__icon-container'>
        <AiOutlineInfoCircle className='job-position-link__icon-container__icon' />
      </span>
      <div className="job-position-link__content-container">
        <span className='job-position-link__job-title'>{props.title}</span>
        {props.location ?
          <span className='job-position-link__location'>{props.location}</span>
          : ''}
      </div>
    </Link>
  );
};

JobLink.defaultProps = locationDefault;

export default JobLink;
