import React from 'react';
import {t} from 'i18next';
import BubbleBox from 'components/BubbleBox/BubbleBox';
import SalaryImg from 'assets/images/icons/salary-icon.svg';
import TasksImg from 'assets/images/icons/tasks-icon.svg';
import ParticipationImg from 'assets/images/icons/participation-icon.svg';
import OpportunityImg from 'assets/images/icons/opportunity-icon.svg';
import VersionsImg from 'assets/images/icons/versions-icon.svg';
import GrowthImg from 'assets/images/icons/growth-icon.svg';
import RemoteImg from 'assets/images/icons/remote-icon.svg';

const WeOfferSectionContent = () => {
  return (
    <div className="content-wrapper">
      <h2 className='section-heading we-offer__header'>{t('We offer')}</h2>

      <div className="we-offer__content-container">
        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__salary-icon"
            imgSource={SalaryImg}
          />
          <p>{t('Competitive salary')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__tasks-icon"
            imgSource={TasksImg}
          />
          <p>{t('Interesting tasks')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__participation-icon"
            imgSource={ParticipationImg}
          />
          <p>{t('Participation')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__opportunity-icon"
            imgSource={OpportunityImg}
          />
          <p>{t('Opportunity')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__versions-icon"
            imgSource={VersionsImg}
          />
          <p>{t('Latest versions')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__growth-icon"
            imgSource={GrowthImg}
          />
          <p>{t('Opportunity to grow')}</p>
        </div>

        <div className="we-offer__content-container__offer-box">
          <BubbleBox
            mode="icon"
            imgClassName="we-offer__remote-icon"
            imgSource={RemoteImg}
          />
          <p>{t('Remote work')}</p>
        </div>

      </div>
    </div>
  );
};

export default WeOfferSectionContent;
