import React from 'react';
import {t} from 'i18next';
import {useWindowWidth} from 'hooks/useWindowWidth';
import DesktopCarousel from './DesktopCarousel';
import MobileCarousel from './MobileCarousel';
import './Clients.scss';

const Clients: React.FC = (): any => {
  const {width} = useWindowWidth();

  const mobileWidthBreakpoint = 880;

  return (
    <div id="clients" className='our-clients'>
      <h2 className='section-heading our-clients__heading'>{t('Our clients')}</h2>

      <div className="custom-carousel our-clients__custom-carousel">
        {width > mobileWidthBreakpoint ? <DesktopCarousel /> : <MobileCarousel />}
      </div>
    </div>
  );
};

export default Clients;
