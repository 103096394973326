import React from 'react';
import {t} from 'i18next';
import './TextArea.scss';

interface ITextAreaProps {
  label: string,
  placeholder: string,
  name: string,
  value: string,
  onChange: any,
  error: string
}

const TextArea: React.FC<ITextAreaProps> = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  error
}): any => {
  return (
    <div className="textarea-container">
      <label className='form-label' htmlFor="nameAndSurname">
        {label}
      </label>
      <textarea
        className='form-textarea'
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
      />
      {error ? (<p className='form-error-message'>{t(error)}</p>) : ''}
    </div>
  );
};

export default TextArea;
