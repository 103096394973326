import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import AttentionInsightLogo from 'assets/images/clients/Attention_Insight_logo.png';
import MysteriumNetworkLogo from 'assets/images/clients/Mysterium_network_logo.png';
import KoditusLogo from 'assets/images/clients/Koditus_logo.png';
import SoftetaLogo from 'assets/images/clients/Softeta_logo.png';
import BlockbankLogo from 'assets/images/clients/Blockbank_logo.png';
import Box4metal from 'assets/images/clients/Box4metal_logo.webp';

const MobileCarousel: React.FC = (): any => {
  return (
    <Carousel
      centerSlidePercentage={100}
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop
      autoPlay
    >
      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img
            className='attention-sight-logo'
            src={AttentionInsightLogo}
            alt="Attention Insight company logo"
          />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          Attention insight
        </h2>
        <p className='our-clients__carousel__company-description'>
          AI-powered design analytics
        </p>
      </div>

      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img
            className='mysterium-logo'
            src={MysteriumNetworkLogo}
            alt="Mysterium Network logo"
          />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          Mysterium Network
        </h2>
        <p className='our-clients__carousel__company-description'>
          An open-source ecosystem of protocols,
          tools and infrastructure to liberate the web
        </p>
      </div>

      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img className='softeta-logo' src={SoftetaLogo} alt="Softeta company logo" />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          Softeta
        </h2>
        <p className='our-clients__carousel__company-description'>
          Software Development Company
        </p>
      </div>

      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img className='koditus-logo' src={KoditusLogo} alt="Koditus company logo" />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          Koditus
        </h2>
        <p className='our-clients__carousel__company-description'>
          Enterprise software development & services
        </p>
      </div>

      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img className='blockbank-logo' src={BlockbankLogo} alt="Blockbank company logo" />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          Blockbank
        </h2>
        <p className='our-clients__carousel__company-description'>
          Online banking
        </p>
      </div>

      <div className="our-clients__carousel__item-container">
        <div className="our-clients__carousel__img-container">
          <img className='box4metal-logo' src={Box4metal} alt="Box4metal company logo" />
        </div>
        <h2 className='section-heading our-clients__carousel__company-name'>
          BOX4METAL
        </h2>
        <p className='our-clients__carousel__company-description'>
          Catalytic converter catolague system
        </p>
      </div>

    </Carousel>
  );
};

export default MobileCarousel;
