import React from 'react';
import './BubbleBox.scss';

interface IIconProps {
  imgSource: string,
  containerClassName?: string | null,
  imgClassName?: string | null,
  mode?: string,
}

const defaultClassName = {containerClassName: null, imgClassName: null, mode: 'icon'};

const BubbleBox: React.FC<IIconProps> = ({
  imgSource, containerClassName, imgClassName, mode
}): any => {
  return (
    <div className={`${containerClassName ?
      `bubble-box ${containerClassName} 
    ${mode === 'icon' ? 'bubble-box--icon' : 'bubble-box--portrait'}`
      : `bubble-box ${mode === 'icon' ? 'bubble-box--icon' : 'bubble-box--portrait'}`}`}
    >
      <div className="bubble-box__inner-container">
        <img
          className={`${imgClassName ? `${imgClassName}` : 'bubble-box__img-default'}`}
          src={imgSource}
          alt="Document icon"
        />
      </div>
    </div>
  );
};

BubbleBox.defaultProps = defaultClassName;

export default BubbleBox;
