import React, {useEffect} from 'react';
import {t} from 'i18next';
import {PageView} from 'google-analytics/PageView';
import {useScrollToTop} from 'hooks/useScrollToTop';
import {BiMouse} from 'react-icons/bi';
import './Cookies.scss';

const cookiesLink = 'https://www.aboutcookies.org/';

const Cookies: React.FC = (): any => {
  useScrollToTop();

  useEffect(() => {
    PageView();
  }, []);

  return (
    <div className='cookies-page'>
      <div className="hero-block">
        <h1 className='hero-block__header'>{t('Cookies')}</h1>

        <div className='hero-block__scroll-icon-box'>
          <BiMouse />
        </div>
      </div>

      <div className="cookies-page__body">
        <div className="cookies-page__content-wrapper content-wrapper">

          <div className="cookies-page__content-container">
            <h2 className='cookies-page__content-container__header--top section-heading'>
              {t('Using cookies')}
            </h2>
            <p className='cookies-page__content-container__text-block'>
              {t('By using cookies')}
            </p>
          </div>

          <div className="cookies-page__content-container">
            <h2 className='cookies-page__content-container__header section-heading'>
              {t('What are cookies')}
            </h2>
            <p className='cookies-page__content-container__text-block'>
              {t('Cookies are')}
            </p>
          </div>

          <div className="cookies-page__content-container">
            <h2 className='cookies-page__content-container__header section-heading'>
              {t('Cookies control')}
            </h2>

            <p className='cookies-page__content-container__text-block'>{t('Disable cookies')}</p>

            <p className='cookies-page__content-container__text-block link-block'>{t('More info')}:
              <a
                className='cookies-page__content-container__cookies-link'
                href={cookiesLink}
                target="_blank"
                rel="noreferrer"
                aria-label="Linkedin page of Mipsas company"
              >
                https://www.aboutcookies.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
