/* eslint-disable max-len */
import React from 'react';
import BubbleBox from 'components/BubbleBox/BubbleBox';
import GlobeIcon from 'assets/images/icons/globe-icon.svg';
import LaptopAndPhoneIcon from 'assets/images/icons/laptop-and-phone-icon.svg';
import LaptopIcon from 'assets/images/icons/laptop-icon.svg';
import PhoneIcon from 'assets/images/icons/phone-and-cogs-icon.svg';
import AppIcon from 'assets/images/icons/app-icon.svg';
import AppWithCogsIcon from 'assets/images/icons/app-with-cogs-icon.svg';
import {t} from 'i18next';
import {BiMouse} from 'react-icons/bi';
import './HeroBlock.scss';

const HeroBlock: React.FC = (): any => {
  return (
    <div className='home__hero-block'>
      <div className="home__icons-container">
        <BubbleBox
          imgSource={GlobeIcon}
          containerClassName="globe-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="globe-box__globe-icon"
        />
        <BubbleBox
          imgSource={LaptopAndPhoneIcon}
          containerClassName="laptop-and-phone-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="laptop-and-phone-box__laptop-and-phone-icon"
        />
        <BubbleBox
          imgSource={LaptopIcon}
          containerClassName="laptop-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="laptop-box__laptop-icon"
        />
        <BubbleBox
          imgSource={PhoneIcon}
          containerClassName="phone-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="phone-box__phone-icon"
        />
        <BubbleBox
          imgSource={AppIcon}
          containerClassName="app-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="app-box__app-icon"
        />
        <BubbleBox
          imgSource={AppWithCogsIcon}
          containerClassName="app-with-cogs-box bubble-box--header-element home__hero-block__icon-bubble"
          imgClassName="app-with-cogs-box__app-with-cogs-icon"
        />
      </div>
      <h1 className='home__header hero-block__header'>
        {t('Software')}
      </h1>

      <h2 className='home__secondary-header'>
        {t('Development')}
      </h2>

      <div className='hero-block__scroll-icon-box hero-block__scroll-icon-box--alt'>
        <BiMouse className='hero-block__scroll-icon-box-icon' />
      </div>
    </div>
  );
};

export default HeroBlock;
