import React from 'react';
import HtmlLogo from 'assets/images/tech-logos/html.svg';
import CssLogo from 'assets/images/tech-logos/css.svg';
import JsLogo from 'assets/images/tech-logos/javascript.svg';
import JavaLogo from 'assets/images/tech-logos/java.svg';
import ReactNativeLogo from 'assets/images/tech-logos/react-native.svg';
import FigmaLogo from 'assets/images/tech-logos/figma.svg';
import PSLogo from 'assets/images/tech-logos/ps.svg';
import AILogo from 'assets/images/tech-logos/ai.svg';
import ElasticLogo from 'assets/images/tech-logos/elastic.svg';
import DotNetCoreLogo from 'assets/images/tech-logos/dot-net-core.svg';
import PostgresLogo from 'assets/images/tech-logos/postgresql.svg';
import RedisLogo from 'assets/images/tech-logos/redis.svg';
import MySqlLogo from 'assets/images/tech-logos/mysql.svg';
import './TechLogos.scss';

const TechLogos: React.FC = (): any => {
  return (
    <div className="content-wrapper">
      <div className="tools-and-frameworks__logo-container">
        <div className="tools-and-frameworks__logo-container__row">
          <img className='tools-and-frameworks__logo' src={HtmlLogo} alt="Html 5 logo" />
          <img className='tools-and-frameworks__logo' src={CssLogo} alt="Css 3 logo" />
          <img className='tools-and-frameworks__logo' src={JsLogo} alt="Javascript logo" />
          <img className='tools-and-frameworks__logo' src={JavaLogo} alt="Java logo" />
          <img className='tools-and-frameworks__logo' src={ReactNativeLogo} alt="Figma logo" />
          <img className='tools-and-frameworks__logo' src={FigmaLogo} alt="Figma logo" />
          <img className='tools-and-frameworks__logo' src={PSLogo} alt="Adobe Photoshop logo" />
          <img
            className='tools-and-frameworks__logo'
            src={AILogo}
            alt="Adobe Illiustrator logo"
          />
        </div>
        <div className="tools-and-frameworks__logo-container__row">
          <img
            className='tools-and-frameworks__logo'
            src={ElasticLogo}
            alt="Elasticsearch logo"
          />
          <img
            className='tools-and-frameworks__logo'
            src={DotNetCoreLogo}
            alt="Dot net core logo"
          />
          <img
            className='tools-and-frameworks__logo'
            src={PostgresLogo}
            alt="PostgresSQL logo"
          />
          <img className='tools-and-frameworks__logo' src={RedisLogo} alt="Redis logo" />
          <img className='tools-and-frameworks__logo' src={MySqlLogo} alt="MySQL logo" />
        </div>
      </div>
    </div>
  );
};

export default TechLogos;
