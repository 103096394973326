import {useState, useEffect} from 'react';

function getWindowScrollYPos () {
  const {scrollY: scrollYPos} = window;
  return {
    scrollYPos,
  };
}

export function useWindowScrollYPos () {
  const [scrollYPosition, setScrollYPosition] = useState(getWindowScrollYPos());

  useEffect(() => {
    function handleScroll () {
      setScrollYPosition(getWindowScrollYPos());
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollYPosition;
}
