import React from 'react';
import {t} from 'i18next';
import {useNavigate} from 'react-router-dom';
import {HOME} from 'constants/routes';
import './ErrorPage.scss';

const ErrorPage: React.FC = (): any => {
  const returnToHomePage = useNavigate();

  return (
    <div className='error-page'>
      <div className='error-page__header-block hero-block'>
        <h1 className='error-page__header hero-block__header'>{t('Page doesnt exist')}</h1>

        <button
          className='error-page__button primary-button'
          type='button'
          onClick={() => returnToHomePage(HOME)}
        >
          {t('Return to main page')}
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
