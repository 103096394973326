/* eslint-disable max-len */
import React from 'react';
import CompanyInfo from 'components/CompanyInfo/CompanyInfo';
import PersonDetailsBox from 'components/PersonDetailsBox/PersonDetailsBox';
import {t} from 'i18next';
import DirectorImg from 'assets/images/people/director.png';
import ManagingDirectorImg from 'assets/images/people/managing.png';
import './ContactUs.scss';
import GoogleMap from 'components/GoogleMap/GoogleMap';

interface IContactUsProps {
  changeEstimateModalState: any;
}

const ContactUs: React.FC<IContactUsProps> = ({changeEstimateModalState}): any => {
  return (
    <div id="contact-us" className='home-contact-us'>
      <div className="content-wrapper">
        <h2 className='section-heading home-contact-us__section-heading'>{t('Contact Us')}</h2>
        <div className="home-contact-us__content-container">
          <div className="home-contact-us__company-info-container">
            <CompanyInfo onClick={changeEstimateModalState} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
