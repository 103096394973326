import React from 'react';
import {t} from 'i18next';
import './FileUpload.scss';

interface IFileUploadProps {
  label: string,
  file: File | null,
  eventHandler: any;
  error: string
}

const FileUpload: React.FC<IFileUploadProps> = ({
  label,
  eventHandler,
  file,
  error
}): any => {
  return (
    <div className="file-upload">
      <input
        type="file"
        accept=".pdf, application/pdf"
        id="fileInput"
        className="file-upload__input"
        onChange={eventHandler}
      />
      <label className='file-upload__label' htmlFor="file-upload">{label}</label>
      <div className="file-upload__wrapper">
        <label className='file-upload__upload-block' htmlFor="fileInput">
          {t('Upload from computer')}
        </label>
        <div className='file-upload__file-name-container'>
          <span className='file-upload__file-name-container__file-name'>
            {file?.name || 'Resume_2022.pdf'}
          </span>
        </div>
      </div>
      {error && (<p className='form-error-message'>{t(error)}</p>)}
    </div>
  );
};

export default FileUpload;
