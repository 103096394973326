import React, {useRef} from 'react';
import {IoCheckmarkCircleOutline, IoClose} from 'react-icons/io5';
import {CSSTransition} from 'react-transition-group';
import './MessegeSentModal.scss';

interface IMessageSentProps {
  messageSentOpen: boolean,
  closeMessageSent: any
}

const MessageSentModal: React.FC<IMessageSentProps> = ({
  messageSentOpen,
  closeMessageSent,
  children
}): any => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={messageSentOpen}
      appear
      timeout={{
        appear: 0,
        enter: 0,
        exit: 500
      }}
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      {(modalStatus) => (
        <div ref={nodeRef}>
          <div className={`message-sent-modal modal-${modalStatus}`}>
            <IoClose className='message-sent-modal__close-btn' onClick={closeMessageSent} />
            <IoCheckmarkCircleOutline className='message-sent-modal__icon' />
            <div className="message-sent-modal__header-container">
              {children}
            </div>
          </div>
        </div>
      )}
    </CSSTransition>
  );
};

export default MessageSentModal;
