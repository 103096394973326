import React from 'react';
import {useWindowWidth} from 'hooks/useWindowWidth';
import OfferSectionCarousel from './OfferSectionCarousel/OfferSectionCarousel';
import WeOfferSectionContent from './SectionContent/SectionContent';
import './WeOfferSection.scss';

const WeOfferSection: React.FC = (): any => {
  const {width} = useWindowWidth();

  const mobileWidthBreakpoint = 640;

  return (
    <section className='we-offer'>
      {width > mobileWidthBreakpoint ? <WeOfferSectionContent /> : <OfferSectionCarousel />}
    </section>
  );
};

export default WeOfferSection;
