import {t} from 'i18next';
import React from 'react';
import './Input.scss';

interface IInputProps {
  label: string,
  placeholder: string,
  value: string,
  onChange: any,
  type: 'text' | 'email' | 'number';
  name: string,
  error?: string,
}

const Input: React.FC<IInputProps> = ({
  label,
  placeholder,
  type,
  name,
  value,
  onChange,
  error
}): any => {
  return (
    <div className="input-container">
      <label className='form-label' htmlFor="nameAndSurname">
        {label}
      </label>
      <input
        className='form-input'
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />
      {error && (<p className='form-error-message'>{t(error)}</p>)}
    </div>
  );
};

Input.defaultProps = {error: ''};

export default Input;
