import React from 'react';
import BubbleBox from 'components/BubbleBox/BubbleBox';
import GlobeIcon from 'assets/images/icons/globe-icon.svg';
import LaptopAndPhoneIcon from 'assets/images/icons/laptop-and-phone-icon.svg';
import LaptopIcon from 'assets/images/icons/laptop-icon.svg';
import PhoneIcon from 'assets/images/icons/phone-and-cogs-icon.svg';
import AppIcon from 'assets/images/icons/app-icon.svg';
import AppWithCogsIcon from 'assets/images/icons/app-with-cogs-icon.svg';
import {t} from 'i18next';
import './ServicesContent.scss';

const ServicesContent: React.FC = () => {
  return (
    <div className="content-wrapper">
      <div className="services__wrapper">
        <div className="services__service-container">
          <BubbleBox
            imgSource={AppWithCogsIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__app-with-cogs-icon"
          />
          <h4>{t('Website development')}</h4>
        </div>
        <div className="services__service-container">
          <BubbleBox
            imgSource={LaptopIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__laptop-icon"
          />
          <h4>{t('Website design development')}</h4>
        </div>
        <div className="services__service-container">
          <BubbleBox
            imgSource={AppIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__app-icon"
          />
          <h4>{t('Website maintenance')}</h4>
        </div>
        <div className="services__service-container">
          <BubbleBox
            imgSource={PhoneIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__phone-icon"
          />
          <h4>{t('Mobile app development')}</h4>
        </div>
        <div className="services__service-container">
          <BubbleBox
            imgSource={LaptopAndPhoneIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__laptop-and-phone-icon"
          />
          <h4>{t('Software development')}</h4>
        </div>
        <div className="services__service-container">
          <BubbleBox
            imgSource={GlobeIcon}
            containerClassName="services__service-container__icon-bubble"
            imgClassName="service__globe-icon"
          />
          <h4>{t('SEO optimizations')}</h4>
        </div>
      </div>
    </div>
  );
};

export default ServicesContent;
