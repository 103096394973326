import React from 'react';
import {t} from 'i18next';
import {useWindowWidth} from 'hooks/useWindowWidth';
import ServicesCarousel from './ServicesCarousel/ServicesCarousel';
import ServicesContent from './ServicesContent/ServicesContent';
import './Services.scss';

const Services: React.FC = (): any => {
  const {width} = useWindowWidth();

  const mobileWidthBreakpoint = 640;

  return (
    <div id="services" className='services'>
      <h2 className='section-heading services__heading'>{t('Services')}</h2>
      {width > mobileWidthBreakpoint ? <ServicesContent /> : <ServicesCarousel />}
    </div>
  );
};

export default Services;
