/* eslint-disable max-len */
import React from 'react';
import {t} from 'i18next';
import {Link} from 'react-router-dom';
import {PRIVACY, HOME_CONTACTS} from 'constants/routes';
import {EventTracker} from 'google-analytics/EventTracker';
import {FaFacebook, FaLinkedinIn} from 'react-icons/fa';
import './Footer.scss';

const Footer : React.FC = (): any => {
  return (
    <footer className='footer'>
      <div className="content-wrapper">
        <div className='footer__content-container'>
          <div className="footer__links-and-copyright-container">
            <div className="footer__links-container">
              <Link
                className='footer__links-container__link'
                to={PRIVACY}
                onClick={() => EventTracker('Link', 'click to navigate', PRIVACY)}
              >
                {t('Privacy')}
              </Link>

              <Link
                className='footer__links-container__link'
                to={HOME_CONTACTS}
                onClick={() => EventTracker('Link', 'click to navigate', HOME_CONTACTS)}
              >
                {t('Contacts')}
              </Link>
            </div>

            <div className="footer__copyright-container">
              <div className="footer__copyright-container__social-media-container">
                <a
                  href='https://www.facebook.com/MIPSASMB'
                  target="_blank"
                  rel="noreferrer"
                  className='footer__copyright-container__social-media-container__link'
                  aria-label="Facebook page of Mipsas company"
                  onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: Facebook`)}
                >
                  <FaFacebook className='footer__copyright-container__social-media-container__icon' />
                </a>
                <a
                  href='https://www.linkedin.com/company/mipsas-mb'
                  target="_blank"
                  rel="noreferrer"
                  className='footer__copyright-container__social-media-container__link'
                  aria-label="Linkedin page of Mipsas company"
                  onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: Linkedin`)}
                >
                  <FaLinkedinIn className='footer__copyright-container__social-media-container__icon' />
                </a>
              </div>
              <span className='footer__copyright-container__copyright-block'>© 2022 Mipsas</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
