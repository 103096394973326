import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import BubbleBox from 'components/BubbleBox/BubbleBox';
import GlobeIcon from 'assets/images/icons/globe-icon.svg';
import LaptopAndPhoneIcon from 'assets/images/icons/laptop-and-phone-icon.svg';
import LaptopIcon from 'assets/images/icons/laptop-icon.svg';
import PhoneIcon from 'assets/images/icons/phone-and-cogs-icon.svg';
import AppIcon from 'assets/images/icons/app-icon.svg';
import AppWithCogsIcon from 'assets/images/icons/app-with-cogs-icon.svg';
import {t} from 'i18next';
import './ServicesCarousel.scss';

const ServicesCarousel: React.FC = (): any => {
  return (
    <div className="custom-carousel">
      <div className="services__carousel">
        <Carousel
          centerSlidePercentage={100}
          centerMode
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={AppWithCogsIcon}
              imgClassName="service__app-with-cogs-icon"
            />
            <h4 className='services__carousel__title'>{t('Website development')}</h4>
          </div>
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={LaptopIcon}
              imgClassName="service__laptop-icon"
            />
            <h4 className='services__carousel__title'>{t('Website design development')}</h4>
          </div>
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={AppIcon}
              imgClassName="service__app-icon"
            />
            <h4 className='services__carousel__title'>{t('Website maintenance')}</h4>
          </div>
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={PhoneIcon}
              imgClassName="service__phone-icon"
            />
            <h4 className='services__carousel__title'>{t('Mobile app development')}</h4>
          </div>
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={LaptopAndPhoneIcon}
              imgClassName="service__laptop-and-phone-icon"
            />
            <h4 className='services__carousel__title'>{t('Mobile app development')}</h4>
          </div>
          <div className="services__carousel__item-container">
            <BubbleBox
              imgSource={GlobeIcon}
              imgClassName="service__globe-icon"
            />
            <h4 className='services__carousel__title'>{t('SEO optimizations')}</h4>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default ServicesCarousel;
