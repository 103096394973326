/* eslint-disable max-len */
import React from 'react';
import {EventTracker} from 'google-analytics/EventTracker';
import './InfoBox.scss';

interface IInfoBoxProps {
  isLink?: boolean
  className?: string | null
  title: string
  children: any
}

const InfoBox: React.FC<IInfoBoxProps> = ({
  isLink,
  className,
  title,
  children
}): any => {
  return (
    isLink ? (
      <a
        href='mailto:info@mipsas.com'
        className={className ? `info-box ${className}` : 'info-box'}
        onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: email`)}
      >
        <span className='info-box__icon-container'>
          {children}
        </span>
        <span className='info-box__title'>{title}</span>
      </a>
    ) : (
      <span className='info-box'>
        <span className='info-box__icon-container'>
          {children}
        </span>
        <span className='info-box__title'>{title}</span>
      </span>
    )
  );
};

InfoBox.defaultProps = {isLink: true, className: null};

export default InfoBox;
