import {t} from 'i18next';
import React, {useState} from 'react';
import Input from 'components/Input/Input';
import IconButton from 'components/Buttons/IconButton/IconButton';
import FileUpload from 'components/FileUploadInput/FileUpload';
import MessageSentModal from 'components/Modals/MessageSent/MessageSentModal';
import {emailRegex, noNumbersRegex} from 'constants/utilities';
import FormLoader from 'components/FormLoader/FormLoader';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import './JobApplyForm.scss';

interface IJobProps {
  jobTitle: string,
}

interface IDefaultFormValues {
  name: string,
  surname: string,
  email: string,
  phoneNumber: string,
  message: string
}

const defaultFormValues: IDefaultFormValues = {
  name: '',
  surname: '',
  email: '',
  phoneNumber: '',
  message: ''
};

const defaultFormErrors = {
  name: '',
  email: '',
  resume: '',
  submitError: ''
};

const JobApplyForm: React.FC<IJobProps> = ({jobTitle}): any => {
  const [formValues, setFormValues] = useState(defaultFormValues);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  const [formSending, setFormSending] = useState<boolean>(false);

  const [isMessageSentOpen, setIsMessageSentOpen] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      surname: formValues.name,
      message: `${formValues.name} has contacted you about ${jobTitle} position.`
    });
  };

  const uploadFile = (e: any): any => {
    const file = e.target.files[0];
    e.target.value = null;

    if (e.target.files !== null && file !== undefined) {
      const fileExtension = file.name.match(/\.([^.]+)$/)[1];
      switch (fileExtension) {
        case 'pdf':
          setSelectedFile(file);
          setFormErrors({...formErrors, resume: ''});
          break;
        default:
          setSelectedFile(null);
          setFormErrors({...formErrors, resume: 'Only pdf'});
      }
    }
  };

  const formInputValidation = (values: any) => {
    const errors: any = {};
    let isValid = true;

    if (!values.name.trim()) {
      errors.name = 'Name is required';
      isValid = false;
    } else if (!noNumbersRegex.test(values.name)) {
      errors.name = 'No numbers allowed';
      isValid = false;
    } else {
      errors.name = '';
    }

    if (!values.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Enter a valid email address';
      isValid = false;
    } else {
      errors.email = '';
    }

    if (!selectedFile) {
      errors.resume = 'Please attach your resume';
      isValid = false;
    } else {
      errors.resume = '';
    }

    setFormErrors(errors);
    return isValid;
  };

  const send = (): void => {
    const endpointFormData = new FormData();

    // Format form data for email.js
    const emailJsFormData = {
      name: formValues.name,
      email: formValues.email,
      phoneNumber: formValues.phoneNumber,
      message: formValues.message
    };

    // Format form data for endpoint
    Object.entries(formValues).forEach(([key, value]) => {
      endpointFormData.append(key, value);
      if (selectedFile) {
        endpointFormData.append('file', selectedFile);
      }
    });

    const emailJsSubmit = new Promise((resolve, reject) => {
      emailjs.send(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_APPLY_TEMPLATE_ID as string,
        {...emailJsFormData},
        process.env.REACT_APP_USER_ID as string
      ).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });

    const apiSubmit = new Promise((resolve, reject) => {
      axios
        .post('https://api.mipsas.com/carrier', endpointFormData, {
          headers: {'Content-Type': 'multipart/form-data'},
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    if (!formErrors.submitError) {
      setFormSending(true);
    }

    Promise.all([emailJsSubmit, apiSubmit])
      .then(() => {
        setFormSending(false);
        setFormValues(defaultFormValues);
        setFormErrors(defaultFormErrors);
        setIsMessageSentOpen(true);
        setSelectedFile(null);
      }).catch(() => {
        setFormSending(false);
        setFormErrors((prevState) => ({
          ...prevState, submitError: 'Something went wrong'
        }));
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (formInputValidation(formValues)) {
      send();
    }
  };

  const closeMessageSent = () => {
    return isMessageSentOpen ? setIsMessageSentOpen(false) : setIsMessageSentOpen(true);
  };

  return (
    <section className="job-apply-section">
      <MessageSentModal
        messageSentOpen={isMessageSentOpen}
        closeMessageSent={closeMessageSent}
      >
        <h2 className='message-sent-modal__header header--top'>
          {t('Message Sent')}
        </h2>
        <h5 className='message-sent-modal__header header--bottom'>
          {t('Thank you for applying')}
        </h5>
      </MessageSentModal>
      <div className="content-wrapper">
        <form className='job-apply-form' onSubmit={handleSubmit}>

          {formSending ? <FormLoader /> : ''}

          <div className="job-apply-form__input-container">
            <Input
              label={t('Name and surname')}
              placeholder={t('Name and surname placeholder')}
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              error={formErrors.name}
            />
          </div>

          <div className="job-apply-form__input-container">
            <Input
              label={t('Email address')}
              placeholder={t('Email placeholder')}
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              error={formErrors.email}
            />
          </div>

          <div className="job-apply-form__input-container">
            <Input
              label={t('Phone number')}
              placeholder={t('Phone number placeholder')}
              type="number"
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
            />
          </div>

          <FileUpload
            label={t('Attatch resume')}
            eventHandler={uploadFile}
            file={selectedFile}
            error={formErrors.resume}
          />

          <p className='form-error-message submit-error job-apply-form__error-message'>
            {t(`${formErrors.submitError || ''}`)}
          </p>

          <div className="job-apply-form__btn-container">
            <IconButton title={t('Complete and send')} type="submit" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default JobApplyForm;
