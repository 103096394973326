import React from 'react';
import {t} from 'i18next';
import Select from 'react-select';
import './CustomSelect.scss';

interface ISelectOption {
  value: string,
  label: string
}

interface ICustomSelectProps {
  label: string,
  placeholder: string,
  name: string,
  options: ISelectOption[];
  value: any,
  onChange: any;
  error: string
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  label,
  placeholder,
  name,
  options,
  value,
  onChange,
  error
}): any => {
  return (
    <div className="select">
      <label className='select__label' htmlFor="nameAndSurname">
        {label}
      </label>
      <Select
        classNamePrefix='custom-select'
        placeholder={placeholder}
        name={name}
        options={options}
        onChange={onChange}
        value={value}
      />
      {error ? (<p className='form-error-message'>{t(error)}</p>) : ''}
    </div>
  );
};

export default CustomSelect;
