/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import {
  ERROR_PAGE, PRIVACY, OPEN_POSITIONS, HOME, COOKIES, PRESENTATION
} from 'constants/routes';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SideMenu from 'components/SideMenu/SideMenu';
import Privacy from 'pages/Privacy/Privacy';
import OpenPositions from 'pages/OpenPositions/OpenPositions';
import Home from 'pages/Home/Home';
import JobDetails from 'pages/JobDetails/JobDetails';
import Cookies from 'pages/Cookies/Cookies';
import Presentation from 'pages/Presentation/Presentation';
import CookiesBanner from 'components/CookiesBanner/CookiesBanner';
import JobsDataEN from './data/JobsDataEN.json';
import JobsDataLT from './data/JobsDataLT.json';
import 'assets/styles/App.scss';

const useMountEffect = (component: any) => useEffect(component, [component]);

const AppRouter: React.FC = (): any => {
  const [isSideMenuOpen, setSideMenuState] = useState<boolean>(false);

  const [currentLanguage, setCurrentLanguage] =
  useState<string | null>(localStorage.getItem('i18nextLng'));

  const myRef = useRef(document.createElement('div'));

  const executeScroll = () => myRef.current.scrollIntoView();
  useMountEffect(executeScroll);

  const renderJobLinks = () => {
    if (currentLanguage === 'lt') {
      return JobsDataLT.map((job) => {
        return (
          <Route
            key={job.title}
            path={`${OPEN_POSITIONS}/${job.url}`}
            element={<JobDetails jobData={job} />}
          />
        );
      });
    }

    return JobsDataEN.map((job) => {
      return (
        <Route
          key={job.title}
          path={`/open-positions/${job.url}`}
          element={<JobDetails jobData={job} />}
        />
      );
    });
  };

  return (
    <BrowserRouter>
      <Header
        sideMenuOpen={isSideMenuOpen}
        currentLanguage={currentLanguage}
        setSideMenuState={setSideMenuState}
        setCurrentLanguage={setCurrentLanguage}
      />
      <SideMenu
        sideMenuOpen={isSideMenuOpen}
        currentLanguage={currentLanguage}
        setSideMenuState={setSideMenuState}
        setCurrentLanguage={setCurrentLanguage}
      />
      <Routes>
        <Route path={HOME} element={<Home currentLanguage={currentLanguage} />} />
        <Route path={OPEN_POSITIONS} element={<OpenPositions currentLanguage={currentLanguage} />} />
        <Route path={PRIVACY} element={<Privacy />} />
        <Route path={COOKIES} element={<Cookies />} />
        <Route path={PRESENTATION} element={<Presentation />} />
        <Route path={ERROR_PAGE} element={<ErrorPage />} />
        {renderJobLinks()};
      </Routes>
      <CookiesBanner />
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
