/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import React from 'react';
import {t} from 'i18next';
import {Link, useLocation} from 'react-router-dom';
import {FiMenu} from 'react-icons/fi';
import {useWindowScrollYPos} from 'hooks/useScrollPosition';
import {EventTracker} from 'google-analytics/EventTracker';
import LOGO from 'assets/images/Mipsas_Logo.png';
import LanguageSelector from 'components/LanguageSelect/LanguageSelect';
import {
  HOME, HOME_ABOUT, HOME_SERVICES, HOME_CLIENTS, OPEN_POSITIONS, HOME_CONTACTS, PRIVACY, ES_PROJECTS
} from 'constants/routes';
import JobsData from 'data/JobsDataEN.json';
import './Header.scss';

interface propsInterface {
  sideMenuOpen: boolean;
  currentLanguage: string | null;
  setSideMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string | null>>;
}

const Header: React.FC<propsInterface> = ({
  sideMenuOpen, setSideMenuState, currentLanguage, setCurrentLanguage
}) => {
  const location = useLocation();

  const paths = JobsData.map((job) => {return `${OPEN_POSITIONS}/${job.url}`;});

  const isNotExcludedPage =
  location.pathname === HOME ||
  location.pathname === OPEN_POSITIONS ||
  location.pathname === PRIVACY ||
  location.pathname === paths.find((url) => url === location.pathname);

  const {scrollYPos} = useWindowScrollYPos();

  const openSideMenu = (): any => {
    if (!sideMenuOpen) {
      setSideMenuState(true);
    }
  };
  return (
    <header className={`${scrollYPos > 200 && isNotExcludedPage ? 'header header--sticky' : 'header'}`}>
      <div className="content-wrapper">
        <div className="header__content-container">

          <Link className='header__logo-container' to={HOME}>
            <img className='header__logo-container__logo' src={LOGO} alt="Mipsas company logo" />
          </Link>

          <FiMenu className='header__hamburger-menu' onClick={openSideMenu} />

          <div className="header__nav-container">
            <nav>
              <a
                className={`header__nav-link ${location.pathname + location.hash === HOME_ABOUT ?
                  'active' : ''}`}
                href={HOME_ABOUT}
                onClick={() => EventTracker('Link', 'click to navigate', HOME_ABOUT)}
              >
                {t('About Us')}
              </a>

              <a
                className={`header__nav-link ${location.pathname + location.hash === HOME_SERVICES ?
                  'active' : ''}`}
                href={HOME_SERVICES}
                onClick={() => EventTracker('Link', 'click to navigate', HOME_SERVICES)}
              >
                {t('Services')}
              </a>

              <a
                className={`header__nav-link ${location.pathname + location.hash === HOME_CLIENTS ?
                  'active' : ''}`}
                href={HOME_CLIENTS}
                onClick={() => EventTracker('Link', 'click to navigate', HOME_CLIENTS)}
              >
                {t('Clients')}
              </a>

              {/* <Link
                // eslint-disable-next-line max-len
                className={`header__nav-link ${location.pathname + location.hash === OPEN_POSITIONS ?
                  'active' : ''}`}
                to={OPEN_POSITIONS}
                onClick={() => EventTracker('Link', 'click to navigate', OPEN_POSITIONS)}
              >
                {t('Career')}
              </Link> */}

              <a
                className={`header__nav-link ${location.pathname + location.hash === HOME_CONTACTS ?
                  'active' : ''}`}
                href={HOME_CONTACTS}
                onClick={() => EventTracker('Link', 'click to navigate', HOME_CONTACTS)}
              >
                {t('Contacts')}
              </a>
              <a
                className={`header__nav-link ${location.pathname + location.hash === ES_PROJECTS ?
                  'active' : ''}`}
                href={ES_PROJECTS}
                onClick={() => EventTracker('Link', 'click to navigate', ES_PROJECTS)}
              >
                {t('EU Projects')}
              </a>
            </nav>

            <div className="header__language-selector-container">
              <LanguageSelector
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
              />
            </div>
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
