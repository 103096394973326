import React from 'react';
import {t} from 'i18next';
import {useWindowWidth} from 'hooks/useWindowWidth';
import './EuProjects.scss';
import euImg from './eu.jpg';
import euEnImg from './eu_en.jpg';

interface ILanguageProps {
  currentLanguage: string | null,
}

const EuProjects: React.FC<ILanguageProps> = ({currentLanguage}): any => {
  const {width} = useWindowWidth();

  const mobileWidthBreakpoint = 640;

  return (
    <div id="eu-projects" className='services'>
      <h2 className='section-heading services__heading'>{t('EU Projects')}</h2>
      <div style={{margin: '50px', alignContent: 'center'}}>
        <img
          style={{maxWidth: '100%', maxHeight: '400px', marginBottom: '30px'}}
          src={currentLanguage === 'lt' ? euImg : euEnImg}
          alt="EU Project Title"
        />
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.1')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.2')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.3')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.4')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.5')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.6')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.7')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.8')}</div>
        <div style={{marginBottom: '30px', marginLeft: '50px'}}>{t('EU Projects Details.9')}</div>
      </div>
    </div>
  );
};

export default EuProjects;
