/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import BubbleBox from 'components/BubbleBox/BubbleBox';
import React, {useEffect} from 'react';
import {t} from 'i18next';
import {BiMoney, BiMouse} from 'react-icons/bi';
import {PageView} from 'google-analytics/PageView';
import {FaLinkedinIn} from 'react-icons/fa';
import {useScrollToTop} from 'hooks/useScrollToTop';
import {EventTracker} from 'google-analytics/EventTracker';
import HandsIcon from 'assets/images/icons/hands-icon.svg';
import CheckmarkDocument from 'assets/images/icons/checkmark-document.svg';
import WeOfferSection from 'components/WeOfferSection/WeOfferSection';
import JobApplyForm from './JobApplyForm/JobApplyForm';
import './JobDetails.scss';

interface IJobDataProp {
  jobData: any,
}

const JobDetails: React.FC<IJobDataProp> = ({jobData}): any => {
  const {
    title,
    mainResponsibilities,
    requirements,
    bonusIfYouHave,
    backendRequirements,
    frontendRequirements,
    salary
  } = jobData;

  useScrollToTop();

  useEffect(() => {
    PageView();
  }, []);

  const mainResponsibilitiesList = mainResponsibilities.map((item: string, i: number) => {
    return (<li key={i}>{item}</li>);
  });

  const requirementsList = requirements.map((item: string, i: number) => {
    return (<li key={i}>{item}</li>);
  });

  const bonusList = bonusIfYouHave?.map((item: string, i: number) => {
    return (<li key={i}>{item}</li>);
  });

  const backendRequirementsList = backendRequirements?.map((item: string, i: number) => {
    return (<li key={i}>{item}</li>);
  });

  const frontendRequirementsList = frontendRequirements?.map((item: string, i: number) => {
    return (<li key={i}>{item}</li>);
  });

  return (
    <div className='job-details-page'>
      <div className='hero-block hero-block--with-background'>

        <h1 className='hero-block__header'>
          {t('We are looking for')}
        </h1>

        <span
          className='hero-block__info-box'
        >
          {`${title}`}
        </span>

        <p className='hero-block__sm-text'>{t('We seek')}</p>

        <div className='hero-block__scroll-icon-box'>
          <BiMouse />
        </div>
      </div>
      <section className="job-details">
        <div className="content-wrapper">
          <div className="job-details__responsibilities-block">
            <div className="job-details__header-container">
              <BubbleBox
                imgClassName="job-details__responsibilities-block__section-icon"
                imgSource={HandsIcon}
              />
              <h2 className='section-heading job-details__content-block-header'>
                {t('Main responsibilities')}
              </h2>
            </div>
            <ul className='job-details__list job-details__main-responsibilities-list'>
              {mainResponsibilitiesList}
            </ul>
          </div>

          <div className="job-details__requirements-block">
            <div className="job-details__header-container">
              <BubbleBox
                imgClassName="job-details__requirements-block__section-icon"
                imgSource={CheckmarkDocument}
              />
              <h2 className='section-heading job-details__content-block-header'>
                {t('Requirements')}
              </h2>
            </div>

            <ul className='job-details__list job-details__requirements-list'>
              {requirementsList}
            </ul>
            {backendRequirements ? (
              <div className="job-details__optional-list-container">
                <h4 className='job-details__optional-list-container__header'>{t('Backend Requirements')}</h4>
                <ul className='job-details__list'>
                  {backendRequirementsList}
                </ul>
              </div>
            ) : ''}
            {frontendRequirements ? (
              <div className="job-details__optional-list-container">
                <h4 className='job-details__optional-list-container__header'>{t('Frontend Requirements')}</h4>
                <ul className='job-details__list'>
                  {frontendRequirementsList}
                </ul>
              </div>
            ) : ''}
            {bonusIfYouHave ? (
              <div className="job-details__optional-list-container">
                <h4 className='job-details__optional-list-container__header'>{t('Bonus if you have')}</h4>
                <ul className='job-details__list'>
                  {bonusList}
                </ul>
              </div>
            ) : ''}
          </div>
        </div>
      </section>
      <WeOfferSection />

      <section className="salary-details">
        <div className="content-wrapper">
          <div className='salary-details__content-box'>
            <span className='salary-details__icon-box'>
              <BiMoney className='salary-details__icon-box__icon' />
            </span>
            <span className='salary-details__title'>{salary}</span>
          </div>

          <p className='salary-details__info'>{t('Exact Offer')}</p>
        </div>
      </section>

      <section className="apply-here">
        <h2 className='section-heading'>{t('Apply here')}</h2>
        <span className='apply-here__mid-text'>{t('Or')}</span>
        <a
          href='https://www.linkedin.com/company/mipsas-mb/'
          target="_blank"
          rel="noreferrer"
          aria-label="A link to apply to a job using LinkedIn"
          className='button-with-icon apply-here__linkedin-btn'
          onClick={() => EventTracker('Link', 'click', `path: "${window.location.pathname}" link: Linkedin apply`)}
        >
          <span className='apply-here__linkedin-btn__icon-container button-with-icon__icon-container'>
            <FaLinkedinIn />
          </span>
          <span className='apply-here__linkedin-btn__title button-with-icon__title'>{t('Apply via LinkedIn')}</span>
        </a>
      </section>

      <JobApplyForm jobTitle={title} />
    </div>
  );
};

export default JobDetails;
